





















import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { clientStores } from "@/store/poultry/client";

@Component({})
export default class SortIndicator extends Vue {
  @Prop() isSelected!: boolean;
  @Prop() sortOrder!: boolean;
 
  store = clientStores.list;
}
